import bg from '../../../static/images/bg-login.jpg';
import { fade } from '@material-ui/core/styles/colorManipulator';

const appFrame = {
  position: 'relative',
  display: 'flex',
  width: '100%',
  zIndex: 1,
};

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    marginTop: 0,
    zIndex: 1,
    overflow: 'auto',
  },
  appFrameInner: {
    ...appFrame,
    height: '100%',
  },
  appFrameOuter: {
    ...appFrame,
    minHeight: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    minHeight: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
    },
  },
  outerContent: {
    background: `url(${bg}) no-repeat center`,
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    },
  },
  outerEmptyContent:{
    background: "#dcdcdc",
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '20px 0'
    }, 
    background: 'none', 
    '& div':{
      width: '100%',
      height: '100%'
    }
  },
  bgbar: {
    alignItems: 'start',
    background: `url(${bg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center 10%',
    display: 'flex',
    height: 160,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    '&:before': {
      content: "''",
      background: '#F12065',
      display: 'block',
      height: '100%',
      left: 0,
      opacity: 0.56,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  mainWrap: {
    height: '100%',
    marginTop: theme.spacing.unit * 5.5,
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 12,
    },
    '& > div': {
      paddingBottom: theme.spacing.unit * 4,
      willChange: 'inherit !important' // hack for floating form issue whne expaded
    }
  },
  preloader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
    background: 'transparent',
    height: 3,
  },
  materialBg: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    opacity: 0.5
  },
  contentPadding: {
    paddingLeft: 20
  },
  hideApp: {
    display: 'none'
  },
  circularProgress: {
    position: 'absolute',
    top: 'calc(50% - 100px)',
    left: 'calc(50% - 100px)',
  },
  brand: {
    height: 54,
    display: 'flex',
    padding: '10px 10px 5px',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 300,
    },
    '& h3': {
      margin: 0,
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: 10,
      color: theme.palette.common.white,
    }
  },
  btn: {},
  icon: {},
  btnPicker: {
    position: 'fixed',
    zIndex: 2,
    right: 0,
    top: 200,
    background: fade(theme.palette.background.paper, 0.8),
    borderRadius: '30px 0 0 30px',
    padding: '4px 8px 4px 4px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[300]}`,
    '& $btn': {
      background: theme.palette.warning.main,
      borderRadius: 30,
      padding: 8,
      boxShadow: theme.shadows[4],
      display: 'flex',
      alignItems: 'center',
      width: 40,
      height: 40,
      textCenter: 'cener',
      overflow: 'hidden',
      color: 'transparent',
      transition: 'all 0.3s ease',
      '& $icon': {
        color: theme.palette.background.paper,
      },
      '&:hover': {
        color: theme.palette.background.paper,
        width: 90
      }
    }
  },
  drawerOpen: {
    marginRight: 325
  },
});

export default styles;
