import { Card, CardContent, Chip, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './nuevaObra-jss'
import { ButtonAction } from "../../atoms"
import { Add, Delete } from '@material-ui/icons'
import classNames from 'classnames'

const StepEquipoLiterario = ({ classes, updateValues, titles, roles, escritores, data, titleRoles, canViewEquipoLit }) => {

    const handleAddRemove = (type, key) => {
        const temp = data;
        if (key !== undefined) {
            temp.splice(key, 1)
        } else {
            temp.push({ version_equipo: "", value: "", type })
        }
        updateValues(temp)
    }

    const handleChange = (event, key) => {
        let temp = data;
        temp[key][event.target.name] = event.target.value;
        updateValues(temp)
    }

    const handleChip = (value, key) => {
        if (canViewEquipoLit) {
            const temp = data;
            temp[key].value = value;
            updateValues(temp)
        }

    }

    return <section className={classes.bodyForm}>
        <Card>
            <CardContent>
            <Typography className={classes.stepHeadingExpand}>Equipos Literarios</Typography>
                <Grid container spacing={16} className={classes.contentContainer}>
                    <Grid item xs={12} sm={6}>
                        {data && data.map((item, key) => <>
                            {item.type === "version" ? <div key={key} className={classNames(classes.flexCell, classes.dataCell, key % 2 && classes.oddCell)}>
                                <div className={classes.fieldContainer}>
                                    <div className={classes.flexCell}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink>
                                                Otras versiones
                                            </InputLabel>
                                            <Select
                                                name="version_equipo"
                                                onChange={(e) => handleChange(e, key)}
                                                value={item.version_equipo}
                                                className={classes.selectEmpty}
                                                displayEmpty
                                                disabled={!canViewEquipoLit}
                                                error={item.version_equipo_err}
                                            >
                                                <MenuItem value={-1}> Otra</MenuItem>
                                                {
                                                    titles.map((title) => (
                                                        <MenuItem key={`titles_${title.value}`} value={title.data}> {title.data} </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {item.version_equipo_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                        </FormControl>
                                        <div className={classes.actionPadding}>
                                            <ButtonAction
                                                rounded
                                                color="default"
                                                disabled={!canViewEquipoLit}
                                                className={classes.buttonRemove}
                                                onClick={() => { handleAddRemove("version", key) }}>
                                                <Delete />
                                            </ButtonAction>
                                        </div>

                                    </div>
                                    {item.version_equipo === -1 &&
                                        <div>
                                            <TextField
                                                label="Escribe la obra"
                                                name="description"
                                                fullWidth
                                                defaultValue=""
                                                margin="normal"
                                                disabled={!canViewEquipoLit}
                                                value={item.description}
                                                onChange={(e) => handleChange(e, key)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={item.description_err}
                                            />
                                            {item.description_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                        </div>}
                                </div>
                                <div className={classNames([classes.flexCell, classes.fieldContainer, classes.cellPaddingTop, classes.chipContainer])}>
                                    <Chip label="Primigenia" className={[getChipClassNames(classes, 'Primigenia'), item.value === "Primigenia" ? classes.chipPrimigenia : null]} onClick={() => handleChip("Primigenia", key)} />
                                    <Chip label="Adaptación" className={[getChipClassNames(classes, 'Adaptación'), item.value === "Adaptación" ? classes.chipAdaptacion : null]} onClick={() => handleChip("Adaptación", key)} />
                                    {item.value_err ? <FormHelperText className={classes.errorHelperColor}>Selecciona una opción</FormHelperText> : null}
                                </div>
                            </div> : null}
                        </>)}
                        {<div className={classes.actionPaddingTop}>
                            <ButtonAction
                                rounded
                                color="default"
                                disabled={!canViewEquipoLit}
                                className={classes.buttonAdd}
                                onClick={() => { handleAddRemove("version") }}>
                                <Add />
                            </ButtonAction>
                        </div>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {data && data.map((item, key) => <>
                            {item.type === "equipo" ? <div key={key} className={classNames(classes.flexCell, classes.dataCell, key % 2 && classes.oddCell)}>
                                <div className={classes.fieldContainer}>
                                    <div className={classes.flexCell}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink>
                                                Equipo Literario
                                            </InputLabel>
                                            <Select
                                                name="version_equipo"
                                                disabled={!canViewEquipoLit}
                                                onChange={(e) => handleChange(e, key)}
                                                value={item.version_equipo}
                                                className={classes.selectEmpty}
                                                displayEmpty
                                                error={item.version_equipo_err}
                                            >
                                                {
                                                    escritores.map((e) => (
                                                        <MenuItem key={`rol_${e.value}`} value={e.label}> {e.label} </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {item.version_equipo_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                        </FormControl>
                                        {
                                            <div className={classes.actionPadding}>
                                                <ButtonAction
                                                    rounded
                                                    color="default"
                                                    disabled={!canViewEquipoLit}
                                                    className={classes.buttonRemove}
                                                    onClick={() => { handleAddRemove("equipo", key) }}>
                                                    <Delete />
                                                </ButtonAction>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={classes.fieldContainer}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>
                                            {titleRoles}
                                        </InputLabel>
                                        <Select
                                            name="value"
                                            disabled={!canViewEquipoLit}
                                            onChange={(e) => handleChange(e, key)}
                                            value={item.value}
                                            className={classes.selectEmpty}
                                            displayEmpty
                                            error={item.value_err}
                                        >
                                            {
                                                roles.map((rol) => (
                                                    <MenuItem key={`equipo_${rol.value}`} value={rol.data}> {rol.data} </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {item.value_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                    </FormControl>
                                </div>
                            </div> : null}
                        </>)}
                        {
                            <div className={classes.actionPaddingTop}>
                                <ButtonAction
                                    rounded
                                    color="default"
                                    disabled={!canViewEquipoLit}
                                    className={classes.buttonAdd}
                                    onClick={() => { handleAddRemove("equipo") }}>
                                    <Add />
                                </ButtonAction>
                            </div>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </section>
}

StepEquipoLiterario.propTypes = {
    updateValues: PropTypes.func.isRequired
}

export default withStyles(styles)(StepEquipoLiterario);


/**
 * Returns the corresponding color for a `Clasificacion` value.
 * 
 *  ventas,
 *  produccion,
 *  adptation -> adaptacion,
 *  original -> primigenia,
 *  vigente,
 *  vencida,
 *  porVencer,
 *  produccionTelevisa,
 *  produccionExtranjera,
 *  noProducida,
 *  inedita
 * 
 * @param {ClassNameMap<string>>} classes 
 * @param {string} clasificacion 
 * @returns {string} Corresponding color for the `clasificacion`.
 */
function getChipClassNames(classes, clasificacion) {

    let color = null;
    let base = classes.chipBase;

    switch (clasificacion) {
        case 'Primigenia':
            base = [classes.chipTipo];
            // color = classes.chipPrimigenia
            break;
        case 'Adaptación':
            base = [classes.chipTipo];
            // color = classes.chipAdaptacion
            break;
        default:
            color = classes.chipDefault;
            break;
    }

    return classNames(
        base,
        color
    );
}