export default {
    acervoTheme: {
        // https://bareynol.github.io/mui-theme-creator/
        palette: {
            type: 'light',
            primary: {
                main: '#F12065',
            },
            secondary: {
                main: '#fcca15',
            },
            warning: {
                main: '#fe5503',
            },
            success: {
                main: '#1a237e',
            },
            graydefault: '#5b6066',
        },
        overrides: {
            MuiAvatar: {
                colorDefault: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderColor: '#F12065 !important',
                },
            },
            MuiCheckbox: {
                checked: {
                    color: '#F12065 !important',
                },
            },
            MUIDataTableHeadCell: {
                root: {
                    minWidth: '180px',
                },
            },
            MUIDataTableFilter: {
                root: {
                    '@media (max-width: 470px)': {
                        width: 285,
                    },
                    '& ul': {
                        width: '365px',
                        // backgroundColor: 'red',
                        '@media (max-width: 470px)': {
                            flexDirection: 'column',
                            width: 260,
                            '& li': {
                                width: '100% !important',
                            },
                        },
                    },
                },
                selectFormControl: {
                    marginRight: '24px',
                    marginBottom: '12px',
                    // '& label':{
                    //   fontSize: "14px"
                    // },
                    // '& div':{
                    //   fontSize: "12px"
                    // }
                },
            },
        },
    },
};
