import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import styles from './../../Catalogos/styles-jss';
import Loader from '../../../../components/Loader/Loader';
//AHDA 17/04/2019
//Added dependency for local storage
import ls from 'local-storage';

import Header from '../../../../components/Catalogue/Header';
import TableCatalogue from '../../../../components/Catalogue/TableCatalogue';

//Modal
import NewWorkDialog from '../../../../components/Works/NewWorkDialog';

//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

//Services
import { apiGET, apiPOST, returnCurrentUserId, apiElasticSearch } from '../../../../services/service_base';
import { sendNotification, sendNotificationToSpecific } from '../../../../services/notifications';
import Modal from "@material-ui/core/Modal";
import { Button, IconButton, LinearProgress, Typography } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

import CheckCircle from '../../../../../static/images/check.svg';

class ObrasLiterarias extends React.Component {
    constructor(props) {
        super(props);
        //AHDA 29/04/2019
        //Added Permissions, Added isEdit
        this.state = {
            //Open form
            openStep: false,
            openSync: false,
            syncStatus: 0,
            //Table
            headers: ["ID", "Folio", "Título de la Obra", "Autor(es)/Nickname", "País", "Destino", "Estatus", "Título Inglés", "Otro Idioma", "Opciones"],
            data: [],
            rawData: [], //AHDA 21/05/2019
            //Selected item
            selected: null,
            isEdit: false,
            //Error 
            openError: false,
            notfType: "error",
            notfMesg: "Algo salió mal, intenta de nuevo más tarde",
            //Loading
            isLoading: false,
            workId: 0,
            //Permissions
            chkEditWorkAdmin: false,
            chkEvaluaWorkAdmin: false,
            chkMaterial: false,
            chkVigencia: false,
            chkEquipoLit: false,
            chkMoreInfo: false,
            chkNewWorkAdmin: false,
            chkSyncWorkAdmin: false,
            chkViewAdminSinopsis: false,
            chkViewAdminPersonaje: false
        };
    }

    ////////////////////////////////////////////////////////////////////////

    //Modal
    handleClickOpen = (item) => {
        if (item !== undefined) {
            this.setState({
                openStep: true, selected: item, workId: item.id_obras, isEdit: true
            });
        }
        else {
            this.setState({ openStep: true, selected: null, workId: 0, isEdit: false });
        }
    };

    handleCloseStep = () => {
        this.setState({ openStep: false, isLoading: true });
        //Carga inicial
        apiGET('/content/getAll').then(
            response => {
                if (response.status === 200) {
                    //AHDA 30/05/2019: Added rawData
                    this.setState({
                        data: response.data,
                        rawData: response.data,
                        isLoading: false
                    })
                }
            }).catch(
                error => {
                    console.log(error);
                    this.setState({
                        openError: true,
                        isLoading: false,
                        notfType: "error",
                        notfMesg: "Algo salió mal, intenta de nuevo más tarde"
                    });
                });
    }

    //Change status 
    handleUpdateStatus = (data, item) => {
        if (this.state.chkEditWorkAdmin) {
            let body = {
                work_id: item.id_obras,
                new_status: item.estatus === 1 ? 0 : 1,
                user_who_updates: returnCurrentUserId(),
                portal: "acervo",
                screen: "confLiteraryWorks"
            };
            let elasticBody = {
                id_obras: item.id_obras,
                valid: item.estatus === 1 ? 0 : 1
            }
            Promise.all([apiPOST('/content/blockWork', body), apiElasticSearch('/doc/upsert-doc', { id: item.id_obras, doc: elasticBody })])
                .then(
                    response => {
                        let auxData = [...this.state.data];
                        let auxRawData = [...this.state.rawData];

                        let auxIndex = this.state.data.findIndex(itm => itm.id_obras === item.id_obras);
                        let auxRawIndex = this.state.rawData.findIndex(itm => itm.id_obras === item.id_obras);

                        auxData[auxIndex].estatus = body.new_status;
                        auxRawData[auxRawIndex].estatus = body.new_status;

                        this.setState({ data: auxData, rawData: auxRawData });
                    }
                ).catch(
                    err => {
                        this.setState({
                            openError: true,
                            isLoading: false,
                            notfType: "error",
                            notfMesg: "Algo salió mal, intenta de nuevo más tarde"
                        });
                    }
                );
        } else {
            this.setState({
                openError: true,
                isLoading: false,
                notfType: "error",
                notfMesg: "No cuentas con los permisos para editar la obra"
            });
        }
    }

    //Notification
    handleCloseError = () => {
        this.setState({ openError: false });
    }

    //For dialog fatal error
    openFatalError = () => {
        this.setState({
            openError: true,
            openStep: false,
            notfType: "error",
            notfMesg: "No se puede continuar con el registro de la obra, intente de nuevo mas tarde"
        });
    }

    sendNotification = (type, bodyEmail, bodyNotification, method, specifiedUser) => {
        if (method === 1) {
            sendNotification(type, bodyEmail, bodyNotification);
        } else {
            sendNotificationToSpecific(type, bodyEmail, bodyNotification, specifiedUser);
        }
    }

    //AHDA 21/05/2019
    //------------------------------- Search
    handleSearch = (search) => {
        let key = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //Get all
        if (key === "") {
            this.setState({ data: [...this.state.rawData] });
            return false;
        }

        let auxData = [...this.state.rawData];
        let filteredData = [];

        auxData.map(item => {
            //console.log(Object.values(item)); 
            const match = Object.values(item).filter(v => {
                if (v) {
                    if (v.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(key) > -1)
                        return v;
                }
            });

            let hasMatch = match.length > 0 ? true : false;

            if (hasMatch) {
                filteredData.push(item);
            }
            return item;
        });
        this.setState({ data: filteredData });
    }
    //------------------------------- End Search

    handleCloseSync = () => {
        this.setState({ openSync: false, syncStatus: 0 })
    }

    handleSubmitSync = () => {
        this.setState({ syncStatus: 1 })
        apiElasticSearch('/dashboard/update', {})
            .finally(() => {
                this.setState({ syncStatus: 2 })
            })
    }

    //Component Life cicle 
    componentDidMount() {

        //AHDA 17/04/2019
        //Added mapping for permissions
        let config = ls.get("user_permissions").filter(x => x.menu_id === 3)[0];
        let chk = config ? JSON.parse(config.grant_configuration) : undefined;
        ls.remove("tableProperties");
        this.setState({ isLoading: true });
        //Carga inicial
        apiGET('/content/getAll').then(
            response => {
                if (response.status === 200) {
                    this.setState({
                        data: response.data,
                        rawData: response.data,
                        isLoading: false,
                        chkEditWorkAdmin: chk ? chk.chkEditWorkAdmin : false,
                        chkEvaluaWorkAdmin: chk ? chk.chkEvaluaWorkAdmin : false,
                        chkViewAdminSinopsis: chk ? chk.chkViewAdminSinopsis : false,
                        chkViewAdminPersonaje: chk ? chk.chkViewAdminPersonaje : false,
                        chkMaterial: chk ? chk.chkMaterial : false,
                        chkVigencia: chk ? chk.chkVigencia : false,
                        chkEquipoLit: chk ? chk.chkEquipoLit : false,
                        chkMoreInfo:chk ? chk.chkMoreInfo :false,
                        chkNewWorkAdmin: chk ? chk.chkNewWorkAdmin : false,
                        chkSyncWorkAdmin: chk ? chk.chkSyncWorkAdmin : false,
                        //isanchez 7.05.2019 add permission
                        chkViewWorkAdmin: chk ? chk.chkViewWorkAdmin : false
                    })
                }
            }).catch(
                error => {
                    console.log(error);
                    this.setState({
                        openError: true,
                        isLoading: false,
                        notfType: "error",
                        notfMesg: "Algo salió mal, intenta de nuevo más tarde",
                        chkEditWorkAdmin: chk ? chk.chkEditWorkAdmin : false,
                        chkEvaluaWorkAdmin: chk ? chk.chkEvaluaWorkAdmin : false,
                        chkViewAdminSinopsis: chk ? chk.chkViewAdminSinopsis : false,
                        chkViewAdminPersonaje: chk ? chk.chkViewAdminPersonaje : false,
                        chkMaterial: chk ? chk.chkMaterial : false,
                        chkVigencia: chk ? chk.chkVigencia : false,
                        chkEquipoLit: chk ? chk.chkEquipoLit : false,
                        chkMoreInfo: chk ? chk.chkMoreInfo : false,
                        chkNewWorkAdmin: chk ? chk.chkNewWorkAdmin : false,
                        chkSyncWorkAdmin: chk ? chk.chkSyncWorkAdmin : false
                    });
                });
    }

    render() {
        const { classes } = this.props;

        //AHDA 04/06/2019
        const returnDisabled = () => {
            if (this.state.chkEditWorkAdmin || this.state.chkEvaluaWorkAdmin || this.state.chkMaterial || this.state.chkViewAdminSinopsis || this.state.chkViewAdminPersonaje) {
                return true;
            } else {
                return false;
            }
        }

        return (
            <div className="marginTopLogo">

                <Paper className={classes.root}>
                    {/*AHDA 17/04/2019: Mapped permissions*/}
                    <Header
                        title="Obras Literarias"
                        tooltip="Nueva Obra"
                        textBtn="Nueva Obra"
                        openForm={() => this.handleClickOpen(undefined)}
                        openSync={() => this.setState({ openSync: true })}
                        canAdd={this.state.chkNewWorkAdmin}
                        search={this.handleSearch}
                        canSync={this.state.chkSyncWorkAdmin}
                    />

                    {/*AHDA 17/04/2019: Mapped permissions
                    AHDA 04/06/2019: Mapped canEditPermission
                    */}
                    {
                        this.state.isLoading ?
                            <Loader text="" /> :
                            //isanchez 07.05.2019 add conditional for view Detail 
                            <TableCatalogue
                                headers={this.state.headers}
                                data={this.state.data}
                                openForm={this.handleClickOpen}
                                updateStatus={this.handleUpdateStatus}
                                showDelete={false}
                                canEdit={returnDisabled()}
                            />
                    }
                </Paper>

                <NewWorkDialog
                    openStep={this.state.openStep}
                    handleCloseStep={this.handleCloseStep}
                    openFatalError={this.openFatalError}
                    workId={this.state.workId}
                    sendNotification={this.sendNotification}
                    isEdit={this.state.isEdit}
                    title={!this.state.selected ? "" : this.state.selected.titulo_original ? this.state.selected.titulo_original : "Título no disponible"}
                    canEdit={this.state.chkEditWorkAdmin}
                    canAddMaterial={this.state.chkMaterial}
                    canViewVigencia={this.state.chkVigencia}
                    canViewEquipoLit={this.state.chkEquipoLit}
                    canViewMoreInfo={this.state.chkMoreInfo}
                    canEvaluate={this.state.chkEvaluaWorkAdmin}
                    canViewSinopsis={this.state.chkViewAdminSinopsis}
                    canViewPersonaje={this.state.chkViewAdminPersonaje}
                />

                {/*For notifications*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openError}
                    autoHideDuration={9000}
                    onClose={this.handleCloseError}>
                    <CustomNotification
                        onClose={this.handleCloseError}
                        variant={this.state.notfType}
                        message={this.state.notfMesg} />
                </Snackbar>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openSync}
                    onClose={this.handleCloseSync}
                    className={classes.paper}
                >
                    <div>
                        <div className={classes.paperHeader}>
                            <Typography
                                    variant="h6"
                                    id="modal-title"
                            >
                                    Sincronizar dashboard
                                </Typography>

                                <IconButton
                                    color="inherit"
                                    onClick={this.handleCloseSync}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                        </div>
                        {this.state.syncStatus == 0 && <>
                            <div className={classes.paperBody}>
                                <Typography>
                                    El proceso de sincronización puede tardar cierto tiempo
                                </Typography>
                                <Typography className={classes.my}>
                                    ¿Deseas continuar?
                                </Typography>
                            </div>
                            <div className={classes.paperFooter}>
                                <Button
                                    onClick={() => this.setState({ openSync: false })}
                                    color="primary"
                                >
                                    Cerrar
                                </Button>
                                <Button
                                    onClick={this.handleSubmitSync}
                                    color="primary"
                                >
                                    Continuar
                                </Button>
                            </div>
                            
                        </>}
                        {this.state.syncStatus == 1 &&
                            <div className={classes.paperBody}>
                                <Typography>
                                    Sincronizando...
                                </Typography>
                                
                                
                                <div className={classes.my}>
                                    <LinearProgress />
                                </div>

                                <Typography align='right'>
                                    No cierre esta página
                                </Typography>
                            </div>
                        }
                        {this.state.syncStatus == 2 &&
                            <div className={classNames(classes.paperBody, classes.boxcheck)}>
                                <img src={CheckCircle} height={48} width={48}  alt="Proceso terminado" />
                                <Typography>
                                    Proceso terminado
                                </Typography>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}



export default withStyles(styles)(ObrasLiterarias);