import React from 'react';
import { Helmet } from 'react-helmet';
import update from 'react-addons-update';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import ls from 'local-storage';
import Typography from '@material-ui/core/Typography';

import brand from '../../../utils/brand';
import ProductCard from './../../../components/CardPaper/ProductCard';
import CustomNotification from '../../../components/Notification/CustomNotification';
import Loader from '../../../components/Loader/Loader';
import SearchProduct from './../../../components/Search/SearchProduct';
import ProductDetail from '../../../components/Gallery/ProductDetail';
import { LanguageConsumer } from '../../../language/LanguageContext';

import { apiPOST, returnCurrentUserId } from '../../../services/service_base';

const styles = theme => ({
  title: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main
  }
});

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      isLoading: true,
      //Catgory
      category: {
        name: '',
        total: 0
      },
      //View detail
      open: false,
      selected: 0,
      selectedProduct: [],
      detailWork: 0,
      loading: false,
      //Notifications
      openError: false,
      messageError: 'errMsg',
      variantError: 'error',
      //Permissions 
      chkFavorite: false,
      chkProductionGeneralView: false,
      chkProspect: false,
      chkSalesGeneralView: false,
      chkApplyForMaterial: false,
      chkAssignProject: false,
      chkEndWork: false,
      chkShareMaterial: false,
      chkUnlockWork: false,
      chkViewWorkBlock: false,
      chkViewScripts: false,
      //Force Refreshing
      projects: 0, //AHDA 19/06/2019
      productor: false
    };
  }

  //Open Detail 
  handleDetailOpen = (product) => {
    this.setState(
      {
        detailWork: 0
      }, () => {
        const request = {
          user: returnCurrentUserId(),
          idObra: product.id_obras,
          screen: 'searchCategory'
        }

        //MARK AS SEARCH IN AUDIT
        apiPOST('/obras/markAsSearch', request);

        if (this.state.open) {
          this.setState({ selected: product.id_obras, selectedProduct: product, loading: true });
        } else {
          this.setState({ open: true, selected: product.id_obras, selectedProduct: product, loading: true });
        }

        //AHDA 24/04/2019
        //Added clasification parameter 
        let clasification = "none";
        const { chkProductionGeneralView, chkSalesGeneralView } = this.state;
        if (chkProductionGeneralView && chkSalesGeneralView) {
          clasification = "none";
        }
        else if (!chkProductionGeneralView && chkSalesGeneralView) {
          clasification = "none";
        }
        else if (chkProductionGeneralView && !chkSalesGeneralView) {
          clasification = "produccion";
        }

        // Obteniendo detalle de la obra
        const requestGetObra = {
          idObra: product.id_obras,
          clasification: clasification
        }

        apiPOST('/obras/getDetail', requestGetObra).then(response => {
          if (response.status === 200) {
            this.setState({ detailWork: response.data, loading: false })
          } else {
            this.setState({ openError: true, loading: false, open: false });
          }
        }).catch(error => {
          this.setState({ openError: true, loading: false, open: false });
        });
      }
    )
  };

  //Close detail
  handleClose = () => {
    this.setState({ open: false, selected: 0, selectedProduct: [] });
  };

  //FOR PROSPECTS
  handleNotifications = () => {
    this.setState({ openError: true, messageError: 'errProspects', variantError: 'info' });
  }

  handleCloseNotification = () => {
    this.setState({ openError: false });
  }

  //AHDA 14/05/2019
  //Added event for empty search
  handleEmptySearch = () => {
    this.setState({ openError: true, messageError: 'errEmptySearch', variantError: 'info' });
  }

  //For filters 
  handleFilters = (filters) => {
    let auxData = {};
    let auxResponse = {};
    let auxTotal = 0, productor = false;
    if (filters.ventas & !filters.produccion) {
      auxData = this.state.data;
      auxTotal = this.state.data.total;
      productor = false;
    }
    else if (!filters.ventas & filters.produccion) {
      auxResponse = this.filterData("Vigente");
      auxData = auxResponse.auxData;
      auxTotal = auxResponse.counter;
      productor = true;
    }
    else if ((filters.ventas && filters.produccion) || (!filters.ventas && !filters.produccion)) {
      auxData = this.state.data;
      auxTotal = this.state.data.total;
      productor = false;
    }
    this.setState({ filteredData: auxData, productor, category: { ...this.state.category, total: auxTotal } });
  }

  filterData = (filter) => {
    let counter = 0;
    let auxData = update(this.state.data, {
      "obras": {
        $apply: function (works) {
          works = works.map(work => {
            if (work.obra_vigencia === filter) {
              counter++;
              return work;
            }
            else
              return undefined;
          })
          return works;
        }
      }
    })
    return { auxData: auxData, counter: counter };
  }

  //AHDA 04/06/2019
  //------------------------ Refreshing Favorites and Prospects 
  handleAddTo = (work, type, added, external) => {

    //AHDA 19/06/2019: Added validation for external projects
    let auxProjects = this.state.projects;
    auxProjects = added ? auxProjects + 1 : auxProjects - 1;
    if (type === 101 && external) {
      this.setState({ projects: auxProjects });
      return false;
    }

    //AHDA 19/06/2019: Validation for only favorites, prospects, projects and searchs
    if (this.state.data.id_categoria === 100 || this.state.data.id_categoria === 101 || this.state.data.id_categoria === 102 || this.state.data.id_categoria === 103) {
      let auxData = [...this.state.data.obras];
      let auxFilteredData = [...this.state.filteredData.obras]

      const auxIdx = auxData.findIndex(w => w.id_obras === work.id_obras);
      const auxFilterIdx = auxFilteredData.findIndex(w => w.id_obras === work.id_obras);

      if (added) {
        if (auxIdx === -1) {
          auxData.splice(0, 0, work);
        }

        if (auxFilterIdx === -1) {
          auxFilteredData.splice(0, 0, work);
        }
      } else {
        if (auxIdx > -1) {
          auxData.splice(auxIdx, 1);
        }

        if (auxFilterIdx > -1) {
          auxFilteredData.splice(auxFilterIdx, 1);
        }
      }

      this.setState({
        data: { ...this.state.data, obras: auxData },
        filteredData: { ...this.state.filteredData, obras: auxFilteredData },
        category: { ...this.state.category, total: auxData.length },
        projects: auxProjects //AHDA 19/06/2019
      })
    }

  }

  componentDidMount() { 
    window.scrollTo(0, 0);
    const url = new URL(window.location.href), category = url.searchParams.get("category")

    let clasification = "none", production = 0;

    if (ls.get("user_permissions") != null) {

      let config = ls.get("user_permissions").filter(x => x.menu_id === 1)[0];
      let chk = config ? JSON.parse(config.grant_configuration) : undefined;

      let configDetail = ls.get("user_permissions").filter(x => x.menu_id === 4)[0];
      let chkDetail = configDetail ? JSON.parse(configDetail.grant_configuration) : undefined;

      this.setState({
        chkFavorite: chk ? chk.chkFavorite : false,
        chkProductionGeneralView: chk ? chk.chkProductionGeneralView : false,
        chkProspect: chk ? chk.chkProspect : false,
        chkSalesGeneralView: chk ? chk.chkSalesGeneralView : false,
        chkApplyForMaterial: chkDetail ? chkDetail.chkApplyForMaterial : false,
        chkAssignProject: chkDetail ? chkDetail.chkAssignProject : false,
        chkEndWork: chkDetail ? chkDetail.chkEndWork : false,
        chkShareMaterial: chkDetail ? chkDetail.chkShareMaterial : false,
        chkUnlockWork: chkDetail ? chkDetail.chkUnlockWork : false,
        chkViewWorkBlock: !chkDetail ? false : chkDetail.chkViewWorkBlock ? chkDetail.chkViewWorkBlock : false,
        chkViewScripts: chkDetail ? chkDetail.chkViewScripts : false,
        chkViewSinopsis: !chkDetail ? false : chkDetail.chkViewSinopsis ? chkDetail.chkViewSinopsis : false,
        chkViewPersonaje: !chkDetail ? false : chkDetail.chkViewPersonaje ? chkDetail.chkViewPersonaje : false,
        chkViewEval: !chkDetail ? false : chkDetail.chkViewEval ? chkDetail.chkViewEval : false,
        chkViewMaterial: !chkDetail ? false : chkDetail.chkViewMaterial ? chkDetail.chkViewMaterial : false,
        chkViewMoreInfo: !chkDetail ? false : chkDetail.chkViewMoreInfo ? chkDetail.chkViewMoreInfo : false,
        chkViewVigencia: !chkDetail ? false : chkDetail.chkViewVigencia ? chkDetail.chkViewVigencia : false,
        chkViewEquiposLit: !chkDetail ? false : chkDetail.chkViewEquiposLit ? chkDetail.chkViewEquiposLit : false,
        chkDownloadFT: !chkDetail ? false : chkDetail.chkDownloadFT ? chkDetail.chkDownloadFT : false
      });


      if (chk.chkProductionGeneralView && !chk.chkSalesGeneralView) {
        production = 1;
      }
      if (ls.get("user_object").rdsUser.asigned_profile === "Productor") {
        production = 1;
      }
    }

    const data = {
      category: category != null ? parseInt(category) : 0,
      user: returnCurrentUserId(),
      clasification,
      production
    };

    apiPOST('/obras/getByCategory', data).then(
      response => {
        if (response.status === 200) {
          this.setState({
            data: response.data,
            filteredData: response.data,
            isLoading: false,
            category: {
              name: response.data.categoria,
              total: response.data.total
            },
            productor: production === 1 ? true : false
          });
        }
        else {
          this.setState({ openError: true, isLoading: false, messageError: 'errMsg', variantError: 'error', filteredData: undefined });
        }
      }
    ).catch(
      err => {
        console.log(err);
        this.setState({ openError: true, isLoading: false, messageError: 'errMsg', variantError: 'error', filteredData: undefined });
      }
    )
  }

  render() {
    const title = brand.name + ' - Resultados de Búsqueda';
    const description = brand.desc;
    const { classes } = this.props;
    const divStyle = {
      margin: 'auto',
      marginTop: '20px'
    }

    const { category, open } = this.state
    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div>
              {/*AHDA 30/04/2019: Added props for permissions*/}
              {/*AHDA 03/06/2019: Added chkProductionGeneralView, chkSalesGeneralView, screen*/}
              {/*AHDA 19/06/2019: addToList*/}
              {
                this.state.detailWork !== 0 &&
                <ProductDetail
                  open={open}
                  close={this.handleClose}
                  detailContent={this.state.selectedProduct}
                  productIndex={this.state.selected}
                  detailWork={this.state.detailWork}
                  handleDetailOpen={this.handleDetailOpen}
                  loading={this.state.loading}
                  canFavorite={this.state.chkFavorite}
                  canAddProspect={this.state.chkProspect}
                  canApplyForMaterial={this.state.chkApplyForMaterial}
                  canAssignProyect={this.state.chkAssignProject}
                  canEndWork={this.state.chkEndWork}
                  canShareMaterial={this.state.chkShareMaterial}
                  canUnlockWork={this.state.chkUnlockWork}
                  canViewWorkBlock={this.state.chkViewWorkBlock}
                  chkViewScripts={this.state.chkViewScripts}
                  chkViewSinopsis={this.state.chkViewSinopsis}
                  chkViewPersonaje={this.state.chkViewPersonaje}
                  chkViewEval={this.state.chkViewEval}
                  chkViewMaterial={this.state.chkViewMaterial}
                  chkViewMoreInfo={this.state.chkViewMoreInfo}
                  chkViewVigencia={this.state.chkViewVigencia}
                  chkViewEquiposLit={this.state.chkViewEquiposLit}
                  chkDownloadFT={this.state.chkDownloadFT}
                  chkProductionGeneralView={this.state.chkProductionGeneralView}
                  chkSalesGeneralView={this.state.chkSalesGeneralView}
                  productor={this.state.productor}
                  screen={'searchCategory'}
                  addToList={this.handleAddTo}
                />
              }

              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
              </Helmet>
              {this.state.data.id_categoria ?
                <SearchProduct
                  handleFilters={this.handleFilters}
                  canViewSales={this.state.chkSalesGeneralView}
                  canViewProduction={this.state.chkProductionGeneralView}
                  onEmptySearch={this.handleEmptySearch}
                  productor={this.state.productor} />
                : null}

              <Typography variant="headline" component="h2" className={classes.title}>
                <b>{lang.searchs.results}</b> {category.name} <small>({category.total} {lang.searchs.titles})</small>
              </Typography>

              <Grid
                container
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
                spacing={16}>

                {/*AHDA 17/04/2019: Added validation for empty object*/}
                {
                  this.state.isLoading ?
                    <div style={divStyle} ><Loader text="Cargando..." /></div> :
                    this.state.filteredData ?
                      this.state.filteredData.obras.map((product, index) => {
                        if (product === undefined) {
                          return null;
                        }
                        return (
                          /*CH 13/05/2019: Se cambia grid*/
                          <Grid item xl={false ? 12 : 2} lg={false ? 12 : 2} md={false ? 12 : 3} sm={false ? 12 : 4} xs={12} key={index.toString()}>
                            {/*AHDA 24/04/2019: Added props for permissions*/}
                            {/*AHDA 04/06/2019: Added canViewProduction, canViewSales, addToList*/}
                            {/*AHDA 19/06/2019: Added projects*/}
                            <ProductCard
                              list={false}
                              detailOpen={() =>
                                this.handleDetailOpen(product)
                              }
                              handleNotifications={() => this.handleNotifications()}
                              product={product}
                              screen={'searchCategory'}
                              canFavorite={this.state.chkFavorite}
                              canAddProspect={product.obra_vigencia==="Vigente"&&this.state.chkProspect}
                              canViewWorkBlock={this.state.chkViewWorkBlock}

                              canViewProduction={this.state.chkProductionGeneralView}
                              canViewSales={this.state.chkSalesGeneralView}
                              productor={this.state.productor}

                              addToList={this.handleAddTo}

                              projects={this.state.projects}
                            />
                          </Grid>
                        );
                      }) : null
                }
              </Grid>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.openError}
                autoHideDuration={6000}
                onClose={this.handleCloseNotification}>
                <CustomNotification
                  onClose={this.handleCloseNotification}
                  variant={this.state.variantError}
                  message={lang.common[`${this.state.messageError}`]} />
              </Snackbar>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

export default withStyles(styles)(Search);
