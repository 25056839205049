import { Button, Divider, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import styles from './nuevaObra-jss'
import classNames from 'classnames'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { ButtonAction } from '../../atoms';
import { Add, Delete } from '@material-ui/icons';
import { LanguageCxt } from '../../language/LanguageContext';

const StepMoreInfo = ({ classes, canViewMoreInfo, producerUsers, assignProspects, assignProject, data, updateValues }) => {
    const { lang } = useContext(LanguageCxt);
    const [prospects, setProspects] = useState(data.assignedProspects || []);

    const handleAddRemove = (key) => {
        const temp = prospects;
        if (key !== undefined) {
            temp[key].isAdded = false;
            if (temp[key].idProspectos) {
                assignProspects([temp[key]])
            }
            temp.splice(key, 1)
        } else {
            temp.push({ assignedUser: "", assignedDate: "", assignedComments: "", isAdded: true })
        }
        setProspects([...temp])
    }

    const handleChange = (input, value) => {
        const temp = data;
        temp[input] = value;
        temp[`${input}_err`] = false;
        updateValues(temp);
    }

    const handleChangeList = (input, event, key) => {
        let temp = prospects;
        temp[key][`${input}_err`] = false;
        if (input === "assignedDate") {
            const f = new Date(event).toISOString();
            temp[key][input] = f.split("T")[0];
        } else {
            temp[key][input] = event.target.value;
        }
        setProspects([...temp]);
    }

    const submitProspects = () => {
        assignProspects(prospects);
    }
    const assigned = prospects.map(e => e.assignedUser);

    return <section className={classes.bodyForm}>
        <Card>
            <CardContent>
                <Typography className={classes.stepHeadingExpand}>{lang.tabMoreInfo.title}</Typography>
                <Grid container spacing={16} className={classes.contentContainer}>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={6}>
                                <div className={classNames(classes.flexRowEnd)}>
                                    <FormControl className={classNames(classes.field)}>
                                        <InputLabel shrink htmlFor="new_author_name">{lang.tabMoreInfo.assignedto}*</InputLabel>
                                        <Select
                                            value={data.assignedTo}
                                            onChange={(e) => handleChange("assignedTo", e.target.value)}
                                            disabled={!canViewMoreInfo || data.idmisProyectos}
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            InputLabelProps={{ shrink: true }}
                                            error={data.assignedTo_err}
                                        >
                                            {
                                                producerUsers.map((e) => (
                                                    <MenuItem key={`producer_${e.value}`} value={e.value}>{e.data}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.flexRowEnd}>
                                {
                                    !data.idmisProyectos &&
                                    <Button variant="contained" color="primary" onClick={() => assignProject(1)} disabled={!canViewMoreInfo}>
                                        {lang.tabMoreInfo.assignWork}
                                    </Button>
                                }
                                {
                                    data.idmisProyectos &&
                                    <Button variant="contained" color="primary" onClick={() => assignProject(0)} disabled={!canViewMoreInfo}>
                                        {lang.tabMoreInfo.unlockWork}
                                    </Button>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        {
                            data.idmisProyectos &&
                            <Button variant="outlined" color="primary" className={classes.minW130} onClick={() => assignProject(2)} disabled={!canViewMoreInfo}>
                                {lang.tabMoreInfo.finishWork}
                            </Button>
                        }
                    </Grid>
                    {prospects.length > 0 && <Grid item xs={12}>
                        <Divider />
                    </Grid>}
                    {prospects.map((item, key) =>
                        <>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.flexCell}>
                                    <FormControl className={classNames(classes.field, classes.w100)}>
                                        <InputLabel shrink htmlFor="new_author_name">{lang.tabMoreInfo.assignedUser}*</InputLabel>
                                        <Select
                                            value={item.assignedUser}
                                            onChange={(e) => handleChangeList("assignedUser", e, key)}
                                            disabled={!canViewMoreInfo}
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            InputLabelProps={{ shrink: true }}
                                            error={item.assignedUser_err}
                                        >
                                            {
                                                producerUsers.map((e) => (
                                                    <MenuItem key={`producer_${e.value}`} disabled={assigned.includes(parseInt(e.value))} value={e.value}>{e.data}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        keyboard={false}
                                        label={`${lang.tabMoreInfo.assignedDate}*`}
                                        className={classes.w100}
                                        format="DD/MM/YYYY"
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        value={item.assignedDate || null}
                                        onChange={(e) => handleChangeList("assignedDate", e, key)}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        disableFuture={true}
                                        clearable={true}
                                        cancelLabel={lang.common.cancel}
                                        clearLabel={lang.common.clear}
                                        placeholder="DD/MM/AAAA"
                                        InputLabelProps={{ shrink: true }}
                                        disabled={!canViewMoreInfo}
                                        error={item.assignedDate_err}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className={classes.flexRowEnd}>
                                <TextField
                                    label={lang.tabMoreInfo.comments}
                                    placeholder={lang.tabMoreInfo.commentsPlaceholder}
                                    multiline
                                    rows="3"
                                    className={classNames(classes.field, classes.w100)}
                                    onChange={(e) => handleChangeList("assignedComments", e, key)}
                                    value={item.assignedComments}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!canViewMoreInfo}
                                />
                                <ButtonAction
                                    rounded
                                    color="default"
                                    disabled={!canViewMoreInfo}
                                    className={classes.buttonRemove}
                                    onClick={() => { handleAddRemove(key) }}>
                                    <Delete />
                                </ButtonAction>
                            </Grid>
                        </>
                    )}
                    {
                        <div className={classes.actionPaddingTop}>
                            <ButtonAction
                                rounded
                                color="default"
                                disabled={!canViewMoreInfo}
                                className={classes.buttonAdd}
                                onClick={() => handleAddRemove()}>
                                <Add />
                            </ButtonAction>
                            {
                                prospects.length > 0 &&
                                <Button variant="contained" color="primary" className={classes.minW130} onClick={submitProspects} disabled={!canViewMoreInfo}>
                                    {lang.tabMoreInfo.assignWork}
                                </Button>
                            }

                        </div>
                    }
                </Grid>
            </CardContent>
        </Card>
    </section>
}

export default withStyles(styles)(StepMoreInfo);