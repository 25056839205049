import { template } from 'lodash';

const styles = (theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  rootTable: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  contentStep: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  contentLegal: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
  },
  stepItem: {
    whiteSpace: 'inherit',
  },
  detailContainer: {
    margin: '-16px auto 0',
    width: '100%',
    paddingTop: 40,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1080,
      minWidth: 1080,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 960,
      minWidth: 960,
      paddingTop: 40,
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      //overflowX: 'hidden',
    },
  },
  bodyForm: {
    position: 'relative',
    background: '#FFFFFF',
    padding: 10,
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 10px',
    },
  },
  field: {
    width: '100%',
    //marginBottom: 0,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    },
  },
  // FORMULARIO FLOTANTE
  fieldFlot: {
    width: '100%',
    marginBottom: 20,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    },
  },
  headingExpand: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  finishText: {
    textAlign: 'center',
    margin: theme.spacing.unit * 3,
    '& p': {
      marginBottom: 5,
      color: theme.palette.primary.main,
    },
  },
  // personajes
  paper: {
    padding: theme.spacing.unit * 2,
    height: '100%',
    color: theme.palette.text.secondary,
    marginBottom: 15,
    '& div:last-child': {
      marginBottom: 0,
    },
  },
  contentFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelTitle: {
    color: theme.palette.primary.main,
    //display: 'inline-block',
  },
  divider: {
    marginTop: 6,
    marginBottom: 6,
  },
  pt2: {
    paddingTop: '10px !important',
  },
  pb3: {
    paddingBottom: '20px !important',
  },
  rightAction: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  info: {
    color: 'rgba(0, 0, 0, 0.38)',
    textAlign: 'center',
  },
  listado: {
    listStyleType: 'disc',
    marginLeft: '1em',
    fontSize: 14,
    '& li': {
      height: 50,
    },
    '& a': {
      color: '#424242',
    },
    '& a:hover': {
      borderBottom: '1px solid #6eb3d1',
    },
    '& a > span': {
      color: '#6eb3d1',
    },
    '& button': {
      float: 'right',
      width: 30,
      height: 30,
      '& svg': {
        fontSize: 18,
      },
    },
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  w100: {
    width: '100%',
  },
  videoContainer: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '120px',
    justifyContent: 'center',
    objectFit: 'cover',
    width: '100%',
    '& img': {
      margin: 'auto',
      border: 'none',
      cursor: 'pointer',
      width: '50px',
    },
  },
  removePadding: {
    padding: 'unset',
  },
  minW130: {
    minWidth: '130px'
  },
  flexRowEnd: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end',
    gap: '10px'
  },
  flexContainer: {
    flexFlow: 'row wrap',
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center !important',
  },
  colorSecondaryText: {
    color: '#a7a7a7',
  },
  alignCenter: {
    //display: 'flex',
    textAlign: 'center',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  rootGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  subTitle: {
    fontSize: '1.2rem',
    marginBottom: '1rem',
    fontWeight: 600,
  },
  title: {
    color: 'white',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  sizeImage: {
    height: '185px',
    objectFit: 'cover',
    width: '329px',
  },
  stepperLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  text: {
    height: 42,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
  },
  minW: {
    minWidth: 225,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  flexItemInput: {
    display: 'flex',
    alignItems: 'center !important',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  stepHeadingCheckbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  stepHeadingExpand: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  checkboxOption: {
    display: 'inline-block',
    minWidth: 100,
  },
  contentContainer: {},
  comentariosBox: {
    marginTop: theme.spacing.unit * 2,
  },
  comentariosInput: {
    marginTop: 0,
  },
  chipTipo: {
    color: theme.palette.graydefault,
    cursor: 'pointer',
    fontSize: 12,
    height: 35,
    lineHeight: 1,
    opacity: 0.5,
    transition: '0.2s ease-in-out opacity',
    textAlign: 'center',
    width: '100%',
    '& span': {
      paddingLeft: 8,
      paddingRight: 8,
      whiteSpace: 'break-spaces',
    },
    '&:hover': {
      opacity: 0.85,
    },
  },
  chipActive: {
    opacity: 1,
  },
  chipPrimigenia: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
    opacity: 1,
    '&:active, &:focus, &:hover': {
      backgroundColor: theme.palette.warning.main,
      color: '#fff',
    },
  },
  chipAdaptacion: {
    backgroundColor: '#f68e5c',
    color: '#fff',
    opacity: 1,
    '&:active, &:focus, &:hover': {
      backgroundColor: '#f68e5c',
      color: '#fff',
    },
  },
  flexCell: {
    display: 'flex',
    flexFlow: ' row nowrap',
  },
  dataCell: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  oddCell: {
    background: 'rgba(253, 253, 253, 1)',
  },
  buttonAdd: {
    flexShrink: 0,
    marginRight: theme.spacing.unit,
  },
  buttonRemove: {
    flexShrink: 0,
    '& svg': {
      height: '0.9em',
      width: '0.9em',
    },
  },
  cellPaddingTop: {
    paddingTop: `${theme.spacing.unit * 1.5}px !important`,
  },
  actionPadding: {
    paddingLeft: `${theme.spacing.unit}px !important`,
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      marginBottom: theme.spacing.unit / 2,
    },
  },
  actionPaddingTop: {
    paddingLeft: `${theme.spacing.unit}px !important`,
    display: 'flex',
    alignItems: 'center',
    minHeight: 60,
    paddingTop: 8,
    paddingBottom: 8,
  },
  chipContainer: {
    alignItems: 'center',
    padding: 'unset !important',
    gap: '8px',
  },
  errorHelperColor:{
    color: 'red'
  },
  fieldContainer: {
    padding: `0 ${theme.spacing.unit}px 0`,
    width: '50%',
  },
  dateControl: {
    width: '50%',
  },
  videoDetails: {
    wordBreak: 'break-all',
    textAlign: 'center',
    '& > *': {
      fontSize: 'small',
      marginTop: theme.spacing.unit,
    },
  },
  flexboxInputs: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  flexGrow: {
    flexGrow: 1,
  },
  checkboxLabel: {
    minWidth: 'unset',
  },
  formControlSpacing: {
    marginBottom: theme.spacing.unit * 2,
  },
  paddingTopLabel: {
    paddingTop: 15,
  },
  videoName: {
    wordBreak: 'break-all',
  },
  alertDialog: {
    '& .confirm': {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  toolbarHeader: {
    background:
      'linear-gradient(93deg, #F12065 0.18%, #F32758 50.82%, #FE5503 101.46%) !important',
  },
  listItem: {
    padding: 0,

    '@media (max-width: 567px)':{
      alignItems: 'inherit',
      flexDirection: 'column',
      rowGap: '8px',
    },

    '& >div:nth-of-type(1)': {
      '@media (min-width: 568px)': {
        maxWidth: '200px'
      }
    },

    '& >div:nth-of-type(3)':{
      display: 'flex',
      position: 'inherit',
      right: 'inherit',
      top: 'inherit',
      transform: 'inherit',
      columnGap: '8px',

      '@media (max-width: 567px)': {
        alignSelf: 'flex-end',
      }
    }
  },
});

export default styles;
